import {useEffect, useState} from "react";
import {customAxios} from "./customAxios";
import {CountryFilters, ProductTypeFilters} from "../model/FiltersDto";
import {Filters} from "../pages/partners/Partners";



export function useFilters() {
    const [loading, setLoading] = useState<boolean>(false);
    const [availableCountries, setAvailableCountries] = useState<CountryFilters[]>();
    const [availableProductTypes, setAvailableProductTypes] = useState<ProductTypeFilters[]>();
    const [error, setError] = useState<Error>();
    const [selectedCountries, setSelectedCountries] = useState<string[]>([])
    const [selectedProductTypes, setSelectedProductTypes] = useState<number[]>([])



    function onCountryChangeGetProductTypes(newSelection: string[]) {
        executeProductTypes(newSelection, []).then(
            apt => {
                const ids = apt.map(v => v.id);
                setSelectedProductTypes(selectedProductTypes.filter(id => id in ids))
            }
        );
    }

    function onProductTypeChangeGetCountries(newSelection: number[]) {
        executeCountries([], newSelection).then(
                cnts => {
                    const codes = cnts.map(v => v.countryCode);
                    setSelectedCountries(selectedCountries.filter(code => codes.indexOf(code) >= 0))
                }
            );
    }

    function onSetInitCountries(countries: string[]) {
        setSelectedCountries(countries);
    }

    function onSelectedCountryChange(country: string) {
        const isSelected = selectedCountries.includes(country);
        const newSelection = isSelected
            ? selectedCountries.filter(s => s !== country)
            : [...selectedCountries, country];
        setSelectedCountries(newSelection);
        onCountryChangeGetProductTypes(newSelection);
    }

    function onSelectedProductType(productTypeId: number) {
        const isSelected = selectedProductTypes.includes(productTypeId);
        const newSelection = isSelected
            ? selectedProductTypes.filter(s => s !== productTypeId)
            : [...selectedProductTypes, productTypeId];
        setSelectedProductTypes(newSelection);
        onProductTypeChangeGetCountries(newSelection);
    }

    async function executeCountries(countries: string[], productTypes: number[]) {
        try {
            setLoading(true);
            const data = await filterCountries(countries, productTypes);
            setAvailableCountries(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    async function executeProductTypes(countries: string[], productTypes: number[]) {
        try {
            setLoading(true);
            const data = await filterProductTypes(countries, productTypes);
            setAvailableProductTypes(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, availableCountries, selectedCountries, availableProductTypes, selectedProductTypes, error,
        executeCountries, executeProductTypes, onSelectedProductType, onSelectedCountryChange, onSetInitCountries
    };
}


export async function filterCountries(countries: string[], productTypes: number[]) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/login`, {
        const params = getParams(countries, productTypes)
        const response = await customAxios.post<CountryFilters[]>(`/v1/search/countries`, null ,{params})
        return response.data;
    }
    catch(error) {
        // customNotifier.error("Error while authenticating!");
        return Promise.reject(error);
    }
}


export async function filterProductTypes(countries: string[], productTypes: number[]) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/login`, {
        const params = getParams(countries, productTypes)
        const response = await customAxios.post<ProductTypeFilters[]>(`/v1/search/product-types`, null ,{params})
        return response.data;
    }
    catch(error) {
        // customNotifier.error("Error while authenticating!");
        return Promise.reject(error);
    }
}


export function getParams(countries: string[] | undefined, productTypes: number[] | undefined) {
    if((!countries || countries.length === 0) && (!productTypes || productTypes.length === 0)) {
        return new URLSearchParams();
    }
    const params = new URLSearchParams();
    if(countries && countries.length > 0) {
        params.set('countries', countries.join(","))
    }
    if(productTypes && productTypes.length > 0) {
        params.set('productTypes', productTypes.join(","))
    }
    return params;
}


export function getFiltersFromParams(params: URLSearchParams): Filters {
    if(!params) return {countries: [], productTypes: []}
    const countriesParam = params.get("countries")
    const productTypesParam = params.get("productTypes")
    let countries: string[] = [];
    if(countriesParam && countriesParam.length >= 3) {
        countries = countriesParam.split(",")
    }
    var productTypes: number[] = [];
    if(productTypesParam && productTypesParam.length >= 1) {
        productTypes = productTypesParam.split(",").map(i => Number.parseInt(i))
    }
    return {countries: countries, productTypes: productTypes}

}



//
//
// export function useFilterCountries() {
//     const [loading, setLoading] = useState<boolean>(false);
//     const [data, setData] = useState<CountryFilters>();
//     const [error, setError] = useState<Error>();
//
//     async function execute(countries: string[], productTypes: number[]) {
//         try {
//             setLoading(true);
//             const data = await filterCountries(countries, productTypes);
//             setData(data);
//             return data;
//         }
//         catch (e) {
//             setError(error);
//             return Promise.reject(e);
//         }
//         finally {
//             setLoading(false);
//         }
//     }
//
//     return {loading, data, error, execute};
// }



// export function useFilterProductTypes() {
//     const [loading, setLoading] = useState<boolean>(false);
//     const [data, setData] = useState<ProductTypeFilters>();
//     const [error, setError] = useState<Error>();
//
//     async function execute(countries: string[], productTypes: number[]) {
//         try {
//             setLoading(true);
//             const data = await filterProductTypes(countries, productTypes);
//             setData(data);
//             return data;
//         }
//         catch (e) {
//             setError(error);
//             return Promise.reject(e);
//         }
//         finally {
//             setLoading(false);
//         }
//     }
//
//     return {loading, data, error, execute};
// }