import React from "react";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {Badge, IconButton, styled, Toolbar, Typography} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import layoutProps from "../props";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: layoutProps.drawerWidth,
        width: `calc(100% - ${layoutProps.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface AppBar {
    drawerOpen: boolean,
    toggleDrawer: () => void;
}

export function AppBar(props: AppBar) {
    return (
        <StyledAppBar position="absolute" open={props.drawerOpen}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                {/* Hamburger */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(props.drawerOpen && {display: 'none'}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    Risk platform
                </Typography>
                {/*<IconButton color="inherit">*/}
                {/*    <Badge badgeContent={4} color="secondary">*/}
                {/*        <NotificationsIcon/>*/}
                {/*    </Badge>*/}
                {/*</IconButton>*/}
            </Toolbar>
        </StyledAppBar>
    );
}