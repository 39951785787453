import {ComposableMap, Geographies, ZoomableGroup} from "react-simple-maps"
import Box from "@mui/material/Box";
import {getCountries} from "../api/getCountries";
import React, {useEffect, useState} from "react";
import {CountryDto} from "../model/CountryDto";
import {Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, useMediaQuery, useTheme} from "@mui/material";
import {GeographyWithTooltip} from "../components/_reusable/GeographyWithTooltip/GeographyWithTooltip";
import {getFill} from "../utilities/getFill";
import {
    ToggleButtonGroup,
    ToggleButton,
    Typography
} from '@mui/material';

const categories = [
    { value: 'scoreNormalised', label: 'Overall' },
    { value: 'indicators.climate.criNormalised', label: 'Climate' },
    { value: 'indicators.eci.eciNormalised', label: 'Economic Complexity' },
    { value: 'indicators.wgi.voiceAndAccountabilityRank', label: 'Voice and Accountability' },
    { value: 'indicators.wgi.politicalStabilityNoViolenceRank', label: 'Political Stability' },
    { value: 'indicators.wgi.governmentEffectivenessRank', label: 'Government Effectiveness' },
    { value: 'indicators.wgi.regulatoryQualityRank', label: 'Regulatory Quality' },
    { value: 'indicators.wgi.ruleOfLawRank', label: 'Rule of Law' },
    { value: 'indicators.wgi.controlOfCorruptionRank', label: 'Control of Corruption' }
];

export function WorldMap() {
    const [countries, setCountries] = useState<Map<string, CountryDto>>();
    const [rank, setRank] = useState<string>('scoreNormalised');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCategoryChange = (event: any, newRank: React.SetStateAction<string> | null) => {
        if (newRank !== null) {
            setRank(newRank);
        }
    };

    useEffect(() => {
        getCountries().then(res => {
            const map = res.reduce(
                (entryMap: Map<string, CountryDto>, e) => entryMap.set(e.iso3, e),
                new Map()
            );
            setCountries(map);
        })
    }, []);

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
        }}>
            <Box sx={{
                flex: isMobile ? 'none' : 1,
                height: isMobile ? '50vh' : 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Paper elevation={3} sx={{flex: 1, overflow: 'hidden', display: 'flex' }}>
                    <ComposableMap
                        projectionConfig={{
                            scale: isMobile ? 500 : 150,
                            center: [0, 20],
                        }}
                        style={{
                            background: "#f0f0f0" // Light grey background
                        }}
                    >
                        <ZoomableGroup center={[10, 5]} zoom={isMobile ? 1 : 2}>
                            <Geographies geography={"/world-countries.json"}>
                                {({geographies}) =>
                                    geographies.map((geo) => (
                                        <GeographyWithTooltip
                                            key={geo.rsmKey}
                                            geo={geo}
                                            fill={getFill(countries, geo.id, rank)}
                                            data={countries?.get(geo.id)}
                                        />
                                    ))
                                }
                            </Geographies>
                        </ZoomableGroup>
                    </ComposableMap>
                </Paper>
            </Box>
            <Box sx={{
                width: isMobile ? '100%' : 300,
                height: isMobile ? 'auto' : '100%',
                pr: 2,
                pt: 2,
                pl: isMobile ? 2 : 0,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{
                    width: '100%',
                    p: 2,
                    overflow: 'auto',
                    border: '1px solid #e0e0e0',
                    borderRadius: 2,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    bgcolor: 'background.paper',
                }}>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Country risks</Typography>

                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <RadioGroup
                            aria-label="categories"
                            name="categories"
                            value={rank}
                            onChange={handleCategoryChange}
                        >
                            {categories.map((category) => (
                                <FormControlLabel
                                    key={category.value}
                                    value={category.value}
                                    control={<Radio color="primary" />}
                                    label={category.label}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '0.9rem',
                                        },
                                        my: 0.5,
                                    }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
}