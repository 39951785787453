import React from 'react';
import { Container, Typography, Paper, Box, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuthenticate } from '../../api/useAuthenticate';
import { LoadingButton } from '@mui/lab';

const FullPageContainer = styled(Container)({
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(6),
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

export function Login() {
    const navigate = useNavigate();
    const { loading, execute } = useAuthenticate();

    const handleSubmit = async (values: { username: string; password: string; }) => {
        try {
            const authData = await execute(values.username, values.password);
            if (authData.authenticated) {
                localStorage.setItem("authData", JSON.stringify(authData));
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <FullPageContainer maxWidth={false}>
            <StyledPaper elevation={3}>
                <Box
                    sx={{
                        backgroundColor: 'purple',
                        borderRadius: '50%',
                        width: 56,
                        height: 56,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}
                >
                    <LockOutlinedIcon sx={{ color: 'white' }} />
                </Box>
                <Typography component="h1" variant="h5" gutterBottom>
                    Risk Supplier Platform
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Welcome Back
                </Typography>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form style={{ width: '100%' }}>
                            <Field
                                as={TextField}
                                margin="normal"
                                fullWidth
                                id="username"
                                name="username"
                                label="Username"
                                error={touched.username && Boolean(errors.username)}
                                helperText={touched.username && errors.username}
                            />
                            <Field
                                as={TextField}
                                margin="normal"
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#1976d2' }}
                                loading={loading}
                            >
                                SIGN IN
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
        </FullPageContainer>
    );
}