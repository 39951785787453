import Container from "@mui/material/Container";
import React from "react";
import {ClientFullDto} from "../../model/ClientFullDto";
import {scaleLinear} from "d3-scale";


export default function AggregatedRisk(
    props: {
        partner: ClientFullDto
    }
) {

    function getGradeColor(value: number): string {
        return partnerGradeScale(value).toString()
    }

    return(
        <Container>
            <div style={{color: "#AAA"}}>AGGREGATED RISK RATING</div>
            <div style={{color: getGradeColor(props.partner.riskScore), fontSize: "40px", fontWeight: "bold"}}>{props.partner.grade}</div>
            <div style={{color: "#AAA", fontSize: "12px"}}>The aggregated risk rating is calculated based on the information provided about the partner</div>
        </Container>
    );
}

const partnerGradeScale = scaleLinear<string>().domain([0, 1]).range([ '#f44336', '#088208a3',])