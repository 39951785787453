import {useState} from "react";
import {customAxios} from "./customAxios";
import {Filters} from "../pages/partners/Partners";
import {ClientsPreviewDto} from "../model/ClientsDto";
import {getParams} from "./filterFunctions";



export function useSearchClients() {
    const [loading, setLoading] = useState<boolean>(false);
    const [clients, setClients] = useState<ClientsPreviewDto[]>([]);
    const [error, setError] = useState<Error>();

    async function executeClientsSearch(filters: Filters) {
        try {
            setLoading(true);
            const data = await filterClients(filters);
            setClients(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, clients, error, executeClientsSearch
    };
}


export async function filterClients(filters: Filters) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/login`, {
        const params = getParams(filters.countries, filters.productTypes)
        const response = await customAxios.post<ClientsPreviewDto[]>(`/v1/search/clients`, null ,{params})
        return response.data;
    }
    catch(error) {
        // customNotifier.error("Error while authenticating!");
        return Promise.reject(error);
    }
}
