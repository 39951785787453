import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from '@mui/x-data-grid';
import React, {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {ProgressBar} from "../../components/_reusable/ProgressBar/ProgressBar";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Card, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import PartnersFilters from "./filters/PartnersFilters";
import {useSearchClients} from "../../api/useSearchClients";
import {getFiltersFromParams} from "../../api/filterFunctions";
import {Loading} from "../../components/_reusable/loading/Loading";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <PartnersFilters/>
            <GridToolbarExport />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

export interface Filters {
    countries: string[];
    productTypes: number[];
}

export default function Partners() {
    const [x, y] = useSearchParams();
    const navigate = useNavigate();
    // const [filters, setFilters] = useState<Filters>();
    const {loading, clients, error, executeClientsSearch} = useSearchClients();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isVerySmall = useMediaQuery('(max-width:500px)');

    // useEffect(() => {
    //     if(filters) {
    //         executeClientsSearch(filters);
    //     }
    // }, []);

    useEffect(() => {
        const filters = getFiltersFromParams(x);
        executeClientsSearch(filters);
    }, [x]);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Id', width: 80},
        {field: 'name', headerName: 'Name', width: 300},
        {
            field: 'rating',
            headerName: 'Risk rating',
            type: 'number',
            width: 200,
            renderCell: ({value}) => <ProgressBar value={value}/>
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 80,
        },
        {
            field: 'productType',
            headerName: 'Product Type',
            width: 250,
        },
        {
            field: 'turnover',
            headerName: 'Turnover',
            width: 200,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => console.log('delete' + params.id)}
                    showInMenu
                />,
                // <GridActionsCellItem
                //     icon={<SecurityIcon/>}
                //     label="Toggle Admin"
                //     onClick={() => console.log('toggle' + params.id)}
                //     showInMenu
                // />,
                <GridActionsCellItem
                    icon={<FileCopyIcon/>}
                    label="Duplicate Partner"
                    onClick={() => console.log('duplicate' + params.id)}
                    showInMenu
                />,

            ],
        },
    ];

    if(loading) {
        return (<Loading/>)
    }
    if(error) {
        return (<div>Error</div>)
    }
    if(!clients || clients.length === 0) {
        return (<div>No data</div>)
    }

    return (
        <Box sx={{height: isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 100px)', width: '100%', p: 2}}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                pt: 1
            }}>
                <Typography
                    variant={isMobile ? "h5" : "h4"}
                    gutterBottom
                    sx={{ textAlign: 'center' }}
                >
                    Partners
                </Typography>
            </Box>
            <Box sx={{ height: '85vh', width: '100%' }}>
                <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <DataGrid
                        rows={clients}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 20},
                            },
                        }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        onRowClick={e => navigate('/partners/' + e.row.id)}
                        // checkboxSelection
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            },
                        }}
                        sx={{
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f0f0f0',
                                fontWeight: 'bold',
                            },
                            '& .hide-on-mobile': {
                                display: { xs: 'none', sm: 'flex' }
                            },
                        }}
                    />
                </Paper>
            </Box>
        </Box>

    )
}


