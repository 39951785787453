import {useState} from "react";
import {customAxios} from "./customAxios";
import {ClientFullDto} from "../model/ClientFullDto";
import {ProductTypeDto, ProductTypeInputDto} from "../model/ProductTypeDto";



export function useProductTypes() {
    const [loading, setLoading] = useState<boolean>(false);
    const [productTypes, setProductTypes] = useState<ProductTypeDto[]>([]);
    const [error, setError] = useState<Error>();

    async function executeGetProductTypes() {
        try {
            setLoading(true);
            const data = await getProductTypes();
            setProductTypes(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, productTypes, error, executeGetProductTypes
    };
}


export async function getProductTypes() {
    try {
        const response = await customAxios.get<ProductTypeDto[]>(`/v1/product-type`, )
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}

export async function deleteProductType(id: number) {
    try {
        const response = await customAxios.delete<ProductTypeDto[]>(`/v1/product-type/` + id, )
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}

export function useAddProductType() {
    const [loading, setLoading] = useState<boolean>(false);
    const [productTypes, setProductTypes] = useState<ProductTypeDto>();
    const [error, setError] = useState<Error>();

    async function executeAddProductType(dto: ProductTypeInputDto) {
        try {
            setLoading(true);
            const data = await addProductType(dto);
            setProductTypes(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, productTypes, error, executeAddProductType
    };
}

export async function addProductType(dto: ProductTypeInputDto) {
    try {
        const response = await customAxios.post(`/v1/product-type`, dto)
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}