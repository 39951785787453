import Container from "@mui/material/Container";
import React from "react";
import {ClientFullDto} from "../../model/ClientFullDto";


export default function PartnerHeader(
    props: {
        partner: ClientFullDto
    }
) {

    return(
        <Container>
            <h1>{props.partner.name}</h1>
            <h3>DunsNo: {props.partner.dunsNo}</h3>
        </Container>
    );
}