import {customAxios} from "./customAxios";


export async function removeProductTypeFromClient(clientId: number, productTypeId: number) {
    try {
        const response = await customAxios.delete(`/v1/client/${clientId}/product-type/${productTypeId}`, )
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}


export async function addProductTypeToClient(clientId: number, productTypeId: number) {
    try {
        const response = await customAxios.post(`/v1/client/${clientId}/product-type/${productTypeId}`, )
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}


export async function addProductTypesToClient(clientId: number, productTypeIds: number[]) {
    try {
        const response = await customAxios.post(`/v1/client/${clientId}/product-type`, productTypeIds)
        return response.data;
    }
    catch(error) {
        return Promise.reject(error);
    }
}
