import React, {useRef, useState} from 'react';
import {Geography} from "react-simple-maps";
import Tooltip from "@mui/material/Tooltip";
import {Box, Button, Divider, Paper, Typography} from '@mui/material';
import useClickOutside from "../../../utilities/useClickOutside";
import {CountryDto} from "../../../model/CountryDto";
import {useNavigate} from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from '@mui/icons-material/Info';

function handleMouseEnter(e: any) {
    e.target.style.stroke = "orange"
}

function handleMouseLeave(e: any) {
    e.target.style.stroke = "white"
}

interface GeographyWithTooltip {
    geo: any;
    fill: string;
    data: CountryDto | undefined;
}

export function GeographyWithTooltip(props: GeographyWithTooltip) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const handleClickOutside = () => {
        setOpen(false);
    };

    useClickOutside([ref1, ref2], handleClickOutside);

    return (
        <Tooltip
            open={open}
            title={
                <Box
                    sx={{
                        p: 2,
                        maxWidth: 300,
                    }}
                >
                    <Typography variant="h6" sx={{ textAlign: 'center', mb: 1, fontWeight: 'bold', color: 'white' }}>
                        {props.data?.name}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<InfoIcon />}
                            onClick={() => navigate(`/countries/${props.data?.iso3}`)}
                            sx={{ flex: 1, mr: 1 }}
                        >
                            Country Info
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<PeopleIcon />}
                            onClick={() => navigate(`/partners?countries=${props.data?.iso3}`)}
                            sx={{ flex: 1, ml: 1 }}
                        >
                            Partners
                        </Button>
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                        Overall Score: {props.data?.scoreNormalised?.toFixed(2)}
                    </Typography>
                </Box>
            }
            onClick={() => {
                setOpen(true);
            }}
        >
            <Geography
                ref={ref2}
                geography={props.geo}
                strokeWidth={0.3}
                stroke={'white'}
                fill={props.fill}
                onMouseOver={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    default: {outline: "none"},
                    hover: {outline: "none"},
                    pressed: {outline: "none"},
                }}
            />
        </Tooltip>
    );
}