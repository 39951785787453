import Container from "@mui/material/Container";
import React from "react";
import {ClientFullDto} from "../../../model/ClientFullDto";
import {DataGrid, GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import {Bar, Root, Value} from "../../../components/_reusable/ProgressBar/ProgressBar";
import DeleteIcon from "@mui/icons-material/Delete";
import {removeProductTypeFromClient} from "../../../api/clientProductType";
import {scaleLinear} from "d3-scale";
import {ProductTypeDto} from "../../../model/ProductTypeDto";


export default function PartnerProductsData(
    props: {
        partner: ClientFullDto,
        reload: () => void;
    }
) {

    function removeProductType(productTypeId: number) {
        removeProductTypeFromClient(props.partner.id, productTypeId).then(response =>
            props.reload()
        ).catch(e => console.log(e))
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Id', width: 100},
        {field: 'subScope', headerName: 'Subscope', width: 300},
        {field: 'rasffRiskScore', headerName: 'RASFF risc score', width: 150},
        // {field: 'rasffRank', headerName: 'RASFF rank', width: 300},
        {field: 'riskLevel', headerName: 'Risk level', width: 100},
        {
            field: 'rasffRank',
            headerName: 'RASFF rank',
            type: 'number',
            width: 150,
            renderCell: (params) => <ProductTypeProgressBar dto={params.row}/>
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Remove',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => removeProductType(Number(params.id))}
                />,
            ],
        },
    ];

    if(!props.partner.productTypes || props.partner.productTypes.length === 0) {
        return <div>No products</div>
    }

    return(
        <Container>
            <DataGrid
                rows={props.partner.productTypes}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                // checkboxSelection
            />
        </Container>
    );

}


export const ProductTypeProgressBar = React.memo(function ProgressBar(props: {dto: ProductTypeDto}) {
    const { rasffRank } = props.dto;
    const valueInPercent = rasffRank * 100;

    return (
        <Root>
            <Value>{`${props.dto.riskLevel} ${valueInPercent.toLocaleString()} %`}</Value>
            <Bar
                style={{ maxWidth: `${valueInPercent}%`, height: "100%", backgroundColor: productTypeBarColorScale(rasffRank).toString(), }}
            />
        </Root>
    );
});

const productTypeBarColorScale = scaleLinear<string>().domain([0, 1]).range([ '#f44336', '#088208a3',])


