import {useState} from "react";
import {AuthData} from "../model/AuthData";
import {authenticate} from "./authenticate";

export function useAuthenticate() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<AuthData>();
    const [error, setError] = useState<Error>();

    async function execute(username: string, password: string) {
        try {
            setLoading(true);
            localStorage.removeItem("authData");
            const data = await authenticate(username, password);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}