import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {CountryFilters} from "../../../model/FiltersDto";


export default function CountryFiltersComponent(
    props: {
        availableCountries: CountryFilters[] | undefined,
        selectedCountries: string[],
        onSelectedCountryChange: (country: string) => void;
    }
) {

    return (
        <Box>
            {props.availableCountries && props.availableCountries.map((country, index) => {
                const isSelected = props.selectedCountries.includes(country.countryCode);
                return (
                    <FormControlLabel
                        key={`countries-${index}`}
                        control={
                            <Checkbox
                                checked={isSelected}
                                onChange={() => props.onSelectedCountryChange(country.countryCode)}
                                size="small" // Use a smaller checkbox
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                {country.countryName}
                                <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
                                    ({country.numberOfSuppliers})
                                </Typography>
                            </Typography>
                        }
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: 0,
                            '& .MuiFormControlLabel-label': {
                                marginLeft: '4px', // Reduce space between checkbox and label
                            }
                        }}
                    />
                );
            })}
        </Box>

    )
}