import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { ClientFullDto } from "../../model/ClientFullDto";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.08)',
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 600,
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const MissingValueTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontStyle: 'italic',
}));

const formatNumber = (num: number | undefined): string => {
    if (num === undefined) return 'N/A';
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

interface InfoItemProps {
    label: string;
    value: string | number | null | undefined;
    formatAsNumber?: boolean;
}

const InfoItem: React.FC<InfoItemProps> = ({ label, value, formatAsNumber = false }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Box mb={2}>
            <LabelTypography variant="body2">{label}</LabelTypography>
            {value !== undefined && value !== null ? (
                <ValueTypography variant="body1">
                    {formatAsNumber ? formatNumber(value as number) : value}
                </ValueTypography>
            ) : (
                <MissingValueTypography variant="body2">
                    N/A - Information should be requested
                </MissingValueTypography>
            )}
        </Box>
    </Grid>
);

interface GeneralInfoProps {
    partner: ClientFullDto;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ partner }) => {
    return (
        <StyledPaper elevation={3}>
            <Container>
                <Grid container spacing={2}>
                    <InfoItem label="Name" value={partner.name} />
                    <InfoItem label="Country" value={partner.country} />
                    <InfoItem label="Revenue" value={partner.revenue} formatAsNumber />
                    <InfoItem label="Revenue from Company" value={partner.migrosRevenue} />
                    <InfoItem label="Legal Structure" value={partner.legalStructure} />
                    <InfoItem label="Line of Business" value={partner.lob} />
                    <InfoItem label="NACE" value={partner.nace} />
                    <InfoItem label="NOGA" value={partner.noga} />
                    <InfoItem label="SIC Code" value={null} />
                    <InfoItem label="Employees" value={partner.numberOfEmployees} />
                    <InfoItem label="Incorporation Year" value={partner.foundingYear} />
                    <InfoItem label="DUNS No." value={partner.dunsNo} />
                    <InfoItem label="Geographic Spread" value={null} />
                    <InfoItem label="Industry Risk Level" value={null} />
                    <InfoItem label="Credit Score" value={null} />
                    <InfoItem label="Debt-to-Equity Ratio" value={null} />
                    <InfoItem label="Liquidity Ratio" value={null} />
                    <InfoItem label="Profitability Margin" value={null} />
                    <InfoItem label="Quality Certifications" value={null} />
                    <InfoItem label="Environmental Certifications" value={null} />
                    <InfoItem label="Industry-Specific Certifications" value={null} />
                    <InfoItem label="Compliance History" value={null} />
                    <InfoItem label="On-Time Delivery Rate" value={null} />
                    <InfoItem label="Defect Rate" value={null} />
                    <InfoItem label="Supply Chain Transparency" value={null} />
                    <InfoItem label="Geopolitical Risk" value={null} />
                </Grid>
            </Container>
        </StyledPaper>
    );
}

export default GeneralInfo;