import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Box, Button, useMediaQuery, useTheme, List, ListItem, ListItemText, Divider } from "@mui/material";
import { ProgressBar } from "../components/_reusable/ProgressBar/ProgressBar";
import { useCountries } from "../api/getCountries";
import { Loading } from "../components/_reusable/loading/Loading";
import { CountryDto } from "../model/CountryDto";
import PeopleIcon from "@mui/icons-material/People";
import { Tabs, Tab } from "@mui/material";
import { WorldMap } from "./WorldMap";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarExport />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

export default function Countries() {
    const navigate = useNavigate();
    const { loading, countries, error, executeGetCountries } = useCountries();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isVerySmall = useMediaQuery('(max-width:500px)');
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        executeGetCountries();
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Country',
            width: isMobile ? 120 : 200,
            renderCell: (params) => (
                <Typography variant="body2" fontWeight="medium">
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'iso3',
            headerName: 'ISO3',
            width: 70,
            align: 'center',
            headerAlign: 'center',
            headerClassName: isMobile ? 'hide-on-mobile' : ''
        },
        {
            field: 'score',
            headerName: 'Score',
            width: isMobile ? 70 : 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography variant="body2" fontWeight="medium">
                    {params.value.toFixed(2)}
                </Typography>
            ),
        },
        {
            field: 'scoreNormalised',
            headerName: 'Rank',
            type: 'number',
            width: isMobile ? 100 : 300,
            renderCell: ({value}) => <ProgressBar value={value}/>,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'partners',
            headerName: 'Partners',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<PeopleIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/partners?countries=${params.row.iso3}`);
                    }}
                >
                </Button>
            ),
        },
    ];

    const customGetRowId = (row: CountryDto): string => `${row.name}-${row.iso2}-${row.iso3}`;

    if (loading) return <Loading />;

    if (isVerySmall) {
        return (
            <Box sx={{ width: '100%', p: 2 }}>
                <Typography variant="h5" gutterBottom>Countries Overview</Typography>
                <List>
                    {countries.map((country) => (
                        <React.Fragment key={customGetRowId(country)}>
                            <ListItem onClick={() => navigate(`/countries/${country.iso3}`)}>
                                <ListItemText
                                    primary={country.name}
                                    secondary={`Score: ${country.score.toFixed(2)}`}
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<PeopleIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/partners?countries=${country.iso3}`);
                                    }}
                                >
                                </Button>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: isMobile ? 'calc(100vh - 56px)' : 'calc(105vh - 100px)',
            width: '100%',
            p: 2
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                pt: 1
            }}>
                <Typography
                    variant={isMobile ? "h5" : "h4"}
                    gutterBottom
                >
                    Countries Overview
                </Typography>
                <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 2 }}>
                    <Tab label="World Map View" />
                    <Tab label="Table View" />
                </Tabs>
            </Box>
            {currentTab === 0 ? (
                <WorldMap />
            ) : (
                <Box sx={{ height: '75vh', width: '100%' }}>
                    <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <DataGrid
                            rows={countries}
                            columns={columns}
                            getRowId={customGetRowId}
                            onRowClick={(e) => navigate('/countries/' + e.row.iso3)}
                            initialState={{
                                pagination: { paginationModel: { pageSize: isMobile ? 10 : 20 } },
                                sorting: { sortModel: [{ field: 'score', sort: 'desc' }] },
                            }}
                            pageSizeOptions={isMobile ? [10, 20, 50] : [10, 20, 50, 100]}
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true
                                },
                            }}
                            sx={{
                                height: '100%',
                                width: '100%',
                                '& .MuiDataGrid-main': {
                                    overflow: 'auto',
                                },
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#f0f0f0',
                                    fontWeight: 'bold',
                                },
                                '& .hide-on-mobile': {
                                    display: { xs: 'none', sm: 'flex' }
                                },
                            }}
                        />
                    </Paper>
                </Box>
            )}

        </Box>
    );
}