import React, {useEffect} from "react";
import {Divider, Grid, Paper} from "@mui/material";
import {useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AggregatedRisk from "./AggregatedRisk";
import PartnerHeader from "./PartnerHeader";
import GeneralInfo from "./GeneralInfo";
import RiskOverview from "./RiskOverview";
import {useGetClient} from "../../api/useGetClient";
import PartnerProducts from "./products/PartnerProducts";
import {styled} from "@mui/system";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    fontWeight: 600,
    textTransform: 'uppercase',
    minWidth: 100,
    transition: 'all 0.2s',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));


export default function Partner() {

    const {partnerId} = useParams();
    const [tabValue, setTabValue] = React.useState(1);

    const {loading, client, error, executeGetClient} = useGetClient();

    useEffect(() => {
        executeGetCurrentClient();
    }, []);

    function executeGetCurrentClient() {
        if(partnerId && !Number.isNaN(partnerId)) {
            executeGetClient(Number.parseInt(partnerId));
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if(loading) {
        return (<div>Loading</div>);
    }

    if(!client) {
        return (<div>NO client</div>);
    }

    return (
        <ContentWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <PartnerHeader partner={client} />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                                    <AggregatedRisk partner={client} />
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                    <StyledPaper elevation={3}>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <StyledTab label="General Info" value={1} />
                                    <StyledTab label="Products" value={2} />
                                    <StyledTab label="Country Risk" value={3} />
                                </Tabs>
                            </Box>
                            {tabValue === 1 && <GeneralInfo partner={client} />}
                            {tabValue === 2 && <PartnerProducts partner={client} reload={executeGetCurrentClient} />}
                            {tabValue === 3 && <RiskOverview partner={client} />}
                        </Grid>
                    </StyledPaper>
                </Grid>
            </Grid>
        </ContentWrapper>
    )
}



