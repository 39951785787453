import Container from "@mui/material/Container";
import React from "react";
import {ClientFullDto} from "../../../model/ClientFullDto";
import PartnerProductsData from "./PartnerProductsData";
import PartnerProductsAddTypes from "./PartnerProductsAddTypes";
import {ProgressBar} from "../../../components/_reusable/ProgressBar/ProgressBar";


export default function PartnerProducts(
    props: {
        partner: ClientFullDto,
        reload: () => void;
    }
) {

    return(
        <Container sx={{minHeight: "480px", overflowY:'auto'}}>
            <div>Products risk: <ProgressBar value={props.partner.productsScore}/></div>
            <PartnerProductsAddTypes partner={props.partner} reload={props.reload} />
            <PartnerProductsData partner={props.partner} reload={props.reload} />
        </Container>
    );

}