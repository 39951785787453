import Container from "@mui/material/Container";
import React, {useEffect} from "react";
import {ClientFullDto} from "../../../model/ClientFullDto";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {addProductTypesToClient, addProductTypeToClient} from "../../../api/clientProductType";
import Box from "@mui/material/Box";
import {useProductTypes} from "../../../api/useProductTypes";
import {ProductTypeDto} from "../../../model/ProductTypeDto";
import ListItemText from "@mui/material/ListItemText";
import {number, string} from "yup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PartnerProductsAddTypes(
    props: {
        partner: ClientFullDto,
        reload: () => void
    }
) {

    const [open, setOpen] = React.useState(false);
    const [selectedTypes, setSelectedTypes] = React.useState<number[]>([]);

    const {loading, productTypes, error, executeGetProductTypes} = useProductTypes()

    useEffect(() => {
        executeGetProductTypes();
    }, []);

    function getAvailableTypes(): ProductTypeDto[] {
        if(!productTypes) return [];
        const existing = props.partner.productTypes.map(pt => pt.id);
        return productTypes.filter(pt => existing.indexOf(pt.id) < 0);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    function sddProductType(productTypeId: number) {
        addProductTypeToClient(props.partner.id, productTypeId).then(response =>
            props.reload()
        ).catch(e => console.log(e))
    }

    function renderPreview(selected: number[]): string {
        return productTypes.filter(pt => selected.indexOf(pt.id) >= 0).map(i => i.subScope).join(', ')
    }


    function save() {
        addProductTypesToClient(props.partner.id, selectedTypes).then(response =>
            props.reload()
        ).catch(e => console.log(e))
    }

    const handleChange = (event: SelectChangeEvent<number[]>) => {
        const typeIds: number[] = event.target.value as number[];
        console.log("handle change ")
        console.log(typeIds)
        setSelectedTypes(productTypes.filter(pt => typeIds.indexOf(pt.id) >= 0).map(i => i.id))

    };

    return(
        <Container>
            {/*<Fab color="primary" aria-label="add"><AddIcon /></Fab>*/}
            <Button color="primary" onClick={handleClickOpen}>Add product type</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Add product types</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputLabel htmlFor="demo-dialog-native">Product types</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedTypes}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => renderPreview(selected)}
                                MenuProps={MenuProps}
                            >
                                {getAvailableTypes().map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        <Checkbox checked={selectedTypes.filter(st => st === type.id).length > 0} />
                                        <ListItemText primary={type.subScope} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={save}>Save</Button>
                </DialogActions>
            </Dialog>

        </Container>
    );

}
