import React, {useEffect} from "react";
import {useCountry} from "../../api/getCountries";
import {Loading} from "../../components/_reusable/loading/Loading";
import {Button, Grid, Paper, Typography, Box, CircularProgress, Tooltip} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CountryDto} from "../../model/CountryDto";
import Container from "@mui/material/Container";
import {scaleLinear} from "d3-scale";
import FlagIcon from "@mui/icons-material/Flag";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from "@mui/icons-material/Info";

const countryScale = scaleLinear<string>().domain([0, 1]).range(['#f44336', '#4caf50']);
interface CountryInfoProps {
    country: CountryDto;
}

export default function CountryInfo({country}: CountryInfoProps) {
    const indicators = [
        {
            name: 'ECI value',
            value: country.indicators.eci.eciNormalised,
            description: 'Economic Complexity Index: Measures the knowledge intensity of an economy by considering the knowledge intensity of the products it exports.'
        },
        {
            name: 'Climate',
            value: country.indicators.climate.criNormalised,
            description: 'Climate Risk Index: Indicates a country\'s level of exposure and vulnerability to extreme weather events.'
        },
        {
            name: 'Voice and accountability',
            value: country.indicators.wgi.voiceAndAccountabilityRank,
            description: 'Measures the extent to which a country\'s citizens are able to participate in selecting their government, as well as freedom of expression, association, and media.'
        },
        {
            name: 'Political stability',
            value: country.indicators.wgi.politicalStabilityNoViolenceRank,
            description: 'Measures the likelihood of political instability and/or politically-motivated violence, including terrorism.'
        },
        {
            name: 'Government effectiveness',
            value: country.indicators.wgi.governmentEffectivenessRank,
            description: 'Reflects perceptions of the quality of public services, civil service, policy formulation and implementation, and the credibility of the government\'s commitment to such policies.'
        },
        {
            name: 'Regulatory quality',
            value: country.indicators.wgi.regulatoryQualityRank,
            description: 'Reflects perceptions of the ability of the government to formulate and implement sound policies and regulations that permit and promote private sector development.'
        },
        {
            name: 'Rule of law',
            value: country.indicators.wgi.ruleOfLawRank,
            description: 'Reflects perceptions of the extent to which agents have confidence in and abide by the rules of society, particularly the quality of contract enforcement, property rights, the police, and the courts.'
        },
        {
            name: 'Control of corruption',
            value: country.indicators.wgi.controlOfCorruptionRank,
            description: 'Reflects perceptions of the extent to which public power is exercised for private gain, including both petty and grand forms of corruption.'
        },
    ];

    return (
        <Grid container spacing={3}>
            {indicators.map((indicator, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <CountryIndicator name={indicator.name} value={indicator.value}
                                      description={indicator.description}/>
                </Grid>
            ))}
        </Grid>
    );
}

interface CountryIndicatorProps {
    name: string;
    value: number;
    description: string;
}

function CountryIndicator({name, value, description}: CountryIndicatorProps) {
    return (
        <Paper elevation={2} sx={{p: 2, height: '100%'}}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" align="center" gutterBottom>{name}</Typography>
                <Box position="relative" display="inline-flex">
                    <CircularProgress variant="determinate" value={value * 100} size={80} thickness={4}
                                      sx={{color: countryScale(value)}}/>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            {`${Math.round(value * 100)}%`}
                        </Typography>
                    </Box>
                </Box>
                <Tooltip
                    title={
                        <Typography sx={{fontSize: '1rem', padding: '8px'}}>
                            {description}
                        </Typography>
                    }
                    arrow
                    placement="top"
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            maxWidth: 300,
                            backgroundColor: 'rgba(0, 0, 0, 0.87)',
                        },
                        '& .MuiTooltip-arrow': {
                            color: 'rgba(0, 0, 0, 0.87)',
                        },
                    }}
                >
                    <InfoIcon sx={{mt: 1, cursor: 'pointer'}}/>
                </Tooltip>
            </Box>
        </Paper>
    );
}