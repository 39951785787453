import {CountryDto} from "../model/CountryDto";
import {scaleLinear} from "d3-scale";
import {getProperty} from 'dot-prop';

export function getFill(countries: Map<string, CountryDto> | undefined, countryCode: string, rank: string): string {
    if (countries) {
        const country = countries.get(countryCode);
        if (country) {
            // @ts-ignore
            const rankProperty = getProperty(country, rank)
            const color = colorScale(rankProperty!)
            if (color) {
                return color.toString()
            } else return '#FFF';
        }
        return '#FFF';
    }
    return '#FFF';
}

// const colorScale = scaleLinear<string>().domain([0, 1]).range(['#d7d7d7', '#000000'])
// const colorScale = scaleLinear<string>().domain([0, 1]).range([ '#000000', '#d7d7d7',])
// New colorful scale
const colorScale = scaleLinear<string>()
    .domain([0, 0.25, 0.5, 0.75, 1])
    .range(['#b64040',
        '#FFA500',
        '#9fc146',
        '#0dc10d',
        '#035912']);