import React, {useEffect} from "react";
import {
    Paper,
    Typography
} from "@mui/material";
import {ClientFullDto} from "../../model/ClientFullDto";
import {useCountry} from "../../api/getCountries";
import {scaleLinear} from "d3-scale";
import Box from "@mui/material/Box";
import {styled} from "@mui/system";
import CountryInfo from "../Country/CountryInfo";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
}));

export default function RiskOverview(
    props: {
        partner: ClientFullDto
    }
) {

    const {loading, country, error, executeGetCounty} = useCountry();

    useEffect(() => {
        executeGetCounty(props.partner.country);
    }, []);

    if (loading) {
        return (<div>Loading</div>)
    }
    if (country === undefined) {
        return (<div>No data</div>)
    }

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h5" gutterBottom align="center" color="primary">
                {country.name} Risk Overview
            </Typography>
            <Box sx={{mt: 2}}>
                <CountryInfo country={country}/>
            </Box>
        </StyledPaper>
    );
}