import { useEffect } from 'react';

// @ts-ignore
const useClickOutside = (elements, handler) => {
    useEffect(() => {
        // @ts-ignore
        const handleClickOutside = (event) => {
            // @ts-ignore
            const isOutside = elements.every(ref => (
                ref && !ref.current?.contains(event.target)
            ));
            if (isOutside) {
                handler();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [elements, handler]);
};

export default useClickOutside;
