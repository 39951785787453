import React from "react";
import { ProductTypeFilters} from "../../../model/FiltersDto";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";


export default function ProductTypeFiltersComponent(
    props: {
        available: ProductTypeFilters[] | undefined,
        selected: number[],
        onChange: (value: number) => void;
    }
) {

    return (
        <Box>
            {props.available && props.available.map((entity, index) => {
                const isSelected = props.selected.includes(entity.id);
                return (
                    <FormControlLabel
                        key={`product-types-${index}`}
                        control={
                            <Checkbox
                                checked={isSelected}
                                onChange={() => props.onChange(entity.id)}
                                size="small" // Use a smaller checkbox
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                {entity.productType}
                                <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
                                    ({entity.numberOfSuppliers})
                                </Typography>
                            </Typography>
                        }
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: 0,
                            '& .MuiFormControlLabel-label': {
                                marginLeft: '4px', // Reduce space between checkbox and label
                            }
                        }}
                    />
                );
            })}
        </Box>

    )
}