import React, {useEffect} from "react";
import {useCountry} from "../../api/getCountries";
import {Loading} from "../../components/_reusable/loading/Loading";
import {Button, Grid, Paper, Typography, Box, CircularProgress, Tooltip} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CountryDto} from "../../model/CountryDto";
import Container from "@mui/material/Container";
import MapIcon from "@mui/icons-material/Map";
import CountryInfo from "./CountryInfo";

export default function Country() {
    const {countryCode} = useParams<{ countryCode: string }>();
    const {loading, country, error, executeGetCounty} = useCountry();

    useEffect(() => {
        if (countryCode) {
            executeGetCounty(countryCode).then(() => console.log("Country data fetched"));
        }
    }, [countryCode]);

    if (loading || !country) return <Loading/>;

    return (
        <Container maxWidth="lg" sx={{py: 4}}>
            <CountryHeader country={country}/>
            <CountryInfo country={country}/>
        </Container>
    );
}

interface CountryHeaderProps {
    country: CountryDto;
}

function CountryHeader({country}: CountryHeaderProps) {
    const navigate = useNavigate();

    return (
        <Paper elevation={3} sx={{p: 3, mb: 4, color: 'black'}}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <img
                        src={`https://flagcdn.com/w160/${country.iso2.toLowerCase()}.png`}
                        alt={`Flag of ${country.name}`}
                        style={{
                            width: 100,
                            height: 'auto',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                            border: '1px solid #e0e0e0'
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h2">{country.name}</Typography>
                    <Typography variant="h5">Rank {getRankString(country.scoreNormalised)}</Typography>
                    <Typography variant="subtitle1">ISO: {country.iso2}/{country.iso3}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MapIcon/>}
                        onClick={() => navigate(`/partners?countries=${country.iso3}`)}
                        sx={{fontWeight: 'bold'}}
                    >
                        Show Partners
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

function getRankString(rank: number): string {
    return `(${Math.round(rank * 100)}/100)`;
}