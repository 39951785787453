import React, { useState } from 'react';
import { Box, Typography, Paper, List, ListItemButton, ListItemText, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// Updated supplier data with countries
const riskiestSuppliersLackInfo = [
    { name: 'GlobalTech Solutions', country: 'USA' },
    { name: 'Acme Manufacturing', country: 'Germany' },
    { name: 'EcoPackage Innovations', country: 'Netherlands' },
    { name: 'Precision Parts Co.', country: 'Japan' },
    { name: 'SwiftLogistics Inc.', country: 'Singapore' },
    { name: 'NextGen Electronics', country: 'South Korea' },
    { name: 'MetalWorks Industries', country: 'Canada' },
    { name: 'ChemSolutions Ltd.', country: 'UK' },
    { name: 'FiberTex Fabrics', country: 'Italy' },
    { name: 'DataSecure Systems', country: 'Israel' }
];

const riskiestSuppliersNegativeInfo = [
    { name: 'TechnoGadgets Corp.', country: 'China' },
    { name: 'PolymerPro Industries', country: 'France' },
    { name: 'AutoParts Express', country: 'Mexico' },
    { name: 'NanoMaterials LLC', country: 'USA' },
    { name: 'AgriSupply Co.', country: 'Brazil' },
    { name: 'GreenEnergy Systems', country: 'Denmark' },
    { name: 'MediEquip Innovations', country: 'Switzerland' },
    { name: 'AeroSpace Components', country: 'USA' },
    { name: 'SmartFabrics Inc.', country: 'Sweden' },
    { name: 'BioTech Essentials', country: 'Ireland' }
];

const suppliersHighImpact = [
    { name: 'MegaChip Technologies', country: 'Taiwan' },
    { name: 'RoboTech Industries', country: 'Japan' },
    { name: 'PowerCell Innovations', country: 'Germany' },
    { name: 'GlobalComm Networks', country: 'USA' },
    { name: 'CloudSoft Solutions', country: 'India' },
    { name: 'Quantum Computing Inc.', country: 'Canada' },
    { name: 'AI Dynamics', country: 'UK' },
    { name: 'NanoTech Materials', country: 'South Korea' },
    { name: 'BioGen Pharmaceuticals', country: 'Switzerland' },
    { name: 'FusionEnergy Corp.', country: 'France' }
];

// @ts-ignore
const RiskBox = ({ title, suppliers }) => {
    const [sortBy, setSortBy] = useState('name');

    const handleClick = (supplier: any) => {
        console.log(`Clicked on supplier: ${supplier.name} from ${supplier.country}`);
    };

    const handleSortChange = (event: any) => {
        setSortBy(event.target.value);
    };

    const sortedSuppliers = [...suppliers].sort((a, b) => {
        if (sortBy === 'name') {
            return a.name.localeCompare(b.name);
        } else {
            return a.country.localeCompare(b.country);
        }
    });

    return (
        <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel>Sort by</InputLabel>
                <Select value={sortBy} label="Sort by" onChange={handleSortChange}>
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="country">Country</MenuItem>
                </Select>
            </FormControl>
            <List dense>
                {sortedSuppliers.map((supplier, index) => (
                    <ListItemButton key={index} onClick={() => handleClick(supplier)}>
                        <ListItemText
                            primary={supplier.name}
                            secondary={supplier.country}
                        />
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

export function Dashboard() {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Supplier Risk Dashboard
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={{ flexBasis: '30%', flexGrow: 1 }}>
                    <RiskBox
                        title="Top 10 Riskiest Suppliers (Lack of Information)"
                        suppliers={riskiestSuppliersLackInfo}
                    />
                </Box>
                <Box sx={{ flexBasis: '30%', flexGrow: 1 }}>
                    <RiskBox
                        title="Top 10 Riskiest Suppliers (Negative Information)"
                        suppliers={riskiestSuppliersNegativeInfo}
                    />
                </Box>
                <Box sx={{ flexBasis: '30%', flexGrow: 1 }}>
                    <RiskBox
                        title="Top 10 Suppliers (>5% Business Impact)"
                        suppliers={suppliersHighImpact}
                    />
                </Box>
            </Box>
        </Box>
    );
}