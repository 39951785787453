import {Button, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React, {useEffect} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteProductType, useProductTypes} from "../../api/useProductTypes";
import {ProductTypeProgressBar} from "../partner/products/PartnerProductsData";
import {Loading} from "../../components/_reusable/loading/Loading";
import AddProductType from "./AddProductType";
import {CenterVertically} from "../../components/_reusable/CenterVertically/CenterVertically";
import PartnersFilters from "../partners/filters/PartnersFilters";


export default function ProductTypes() {
    const {loading, productTypes, error, executeGetProductTypes} = useProductTypes();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        executeGetProductTypes().then(res => console.log()).catch(err => console.log(err))
    }, []);

    function removeProductType(productTypeId: number) {
        deleteProductType(productTypeId).then(response =>
            executeGetProductTypes()
        ).catch(e => console.log(e))
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <AddProductType reload={reload}/>
                <GridToolbarExport />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Id', width: 100},
        {field: 'subScope', headerName: 'Subscope', width: 300},
        {field: 'rasffRiskScore', headerName: 'RASFF Risk Score', width: 150},
        {field: 'riskLevel', headerName: 'Risk Level', width: 100},
        {
            field: 'rasffRank',
            headerName: 'RASFF Rank',
            type: 'number',
            width: 300,
            renderCell: (params) => <ProductTypeProgressBar dto={params.row}/>
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Remove',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => removeProductType(Number(params.id))}
                />,
            ],
        },
    ];

    function reload() {
        console.log("reload")
        executeGetProductTypes().then(r => console.log())
    }

    if(loading) return <Loading/>

    if(error) {
        return (<div>Error</div>)
    }


    return (
        <Box sx={{height: isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 100px)', width: '100%', p: 2}}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                pt: 1
            }}>
                <Typography
                    variant={isMobile ? "h5" : "h4"}
                    gutterBottom
                    sx={{ textAlign: 'center' }}
                >
                    Product types
                </Typography>
            </Box>
            <Box sx={{ height: '85vh', width: '100%' }}>
                <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <DataGrid
                        rows={productTypes}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 20},
                            },
                        }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        // onRowClick={e => navigate('/partners/' + e.row.id)}
                        // checkboxSelection
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            },
                        }}
                        sx={{
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f0f0f0',
                                fontWeight: 'bold',
                            },
                            '& .hide-on-mobile': {
                                display: { xs: 'none', sm: 'flex' }
                            },
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

