import React from 'react';
import {Divider, Grid, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Weights from "./Weights";
import PartnersImport from "./PartnersImport";
import WeightsDetails from "./WeightsDetails";

export default function Settings() {
    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            Risk Weights
                        </Typography>
                        <Divider sx={{mb: 2}}/>
                        <Weights />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h4" gutterBottom>
                            Weights Details
                        </Typography>
                        <Divider sx={{mb: 2}}/>
                        <WeightsDetails />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            Partner Import
                        </Typography>
                        <PartnersImport />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}