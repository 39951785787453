import {useState} from "react";
import {customAxios} from "./customAxios";
import {ClientFullDto} from "../model/ClientFullDto";



export function useGetClient() {
    const [loading, setLoading] = useState<boolean>(false);
    const [client, setClient] = useState<ClientFullDto>();
    const [error, setError] = useState<Error>();

    async function executeGetClient(id: number) {
        try {
            setLoading(true);
            const data = await getClient(id);
            setClient(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, client, error, executeGetClient
    };
}


export async function getClient(id: number) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/login`, {
        const response = await customAxios.get<ClientFullDto>(`/v1/client/` + id, )
        return response.data;
    }
    catch(error) {
        // customNotifier.error("Error while authenticating!");
        return Promise.reject(error);
    }
}
