import React from 'react';
import {Navigate} from "react-router-dom";

interface Authenticated {
    children: React.ReactNode;
}

export function Authenticated(props: Authenticated) {

    if (localStorage.getItem('authData')) return props.children;
    else return <Navigate to={'/login'}/>;
}