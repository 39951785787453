import {customAxios} from "./customAxios";
import {useState} from "react";


export function usePartnersImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(formData: FormData) {
        try {
            setLoading(true);
            const data = await partnersImport(formData);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, error, execute
    };
}

async function partnersImport(formData: FormData): Promise<void> {
    const response = await customAxios.post('/v1/client/import', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
