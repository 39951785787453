import React, {useEffect, useRef, useState} from "react";
import useClickOutside from "../../../utilities/useClickOutside";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {useSearchParams} from "react-router-dom";
import {getParams, useFilters} from "../../../api/filterFunctions";
import CountryFiltersComponent from "./CountryFiltersComponent";
import ProductTypeFiltersComponent from "./ProductTypeFiltersComponent";
import {CenterVertically} from "../../../components/_reusable/CenterVertically/CenterVertically";


export default function PartnersFilters(
    // props: {onApplyFilters: (filters: Filters) => void}
) {

    const [open, setOpen] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [x, y] = useSearchParams();


    const {
        loading, availableCountries, selectedCountries, availableProductTypes, selectedProductTypes, error,
        executeCountries, executeProductTypes, onSelectedProductType, onSelectedCountryChange, onSetInitCountries
    } = useFilters();

    useEffect(() => {
        const country = x.get('countries');
        const countries = country ? country.split(",") : [];
        if (countries && countries.length > 0) {
            onSetInitCountries(countries);
        }
        executeCountries([], []);
        executeProductTypes(countries, []);
        // props.onApplyFilters({countries: countries, productTypes: []});
        // y(getParams(selectedCountries, selectedProductTypes));

    }, []);


    // useEffect(() => {
    //     console.log("use effect x")
    //     const country = x.get('countries');
    //     const type = x.get('productTypes');
    //     const countries = country ? country.split(",") : [];
    //     const types = type ? type.split(",").map(i => Number(i)) : [];
    //     if(countries && countries.length > 0) {
    //         onSetInitCountries(countries);
    //     }
    //     executeCountries([], types);
    //     executeProductTypes(countries, []);
    //     // props.onApplyFilters({countries: countries, productTypes: []});
    //     y(getParams(selectedCountries, selectedProductTypes));
    // }, [x]);

    const handleClickOutside = () => {
        setOpen(false);
    };
    useClickOutside([ref1, ref2], handleClickOutside);


    function apply() {
        // SET url params
        y(getParams(selectedCountries, selectedProductTypes));
        // props.onApplyFilters({countries: selectedCountries, productTypes: selectedProductTypes});
    }

    return (
        <CenterVertically>
            <Tooltip
                open={open}
                title={
                    <>
                        <Box
                            ref={ref1}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "70vh",
                                overflowY: "auto",
                                paddingBottom: "60px",
                            }}
                        >
                            <Paper sx={{ p: 2, mb: 1, bgcolor: 'white' }}>
                                <Typography variant="h6" gutterBottom color="primary">
                                    Countries
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <CountryFiltersComponent
                                    availableCountries={availableCountries}
                                    selectedCountries={selectedCountries}
                                    onSelectedCountryChange={onSelectedCountryChange}
                                />
                            </Paper>

                            <Paper sx={{ p: 2, bgcolor: 'white' }}>
                                <Typography variant="h6" gutterBottom color="primary">
                                    Product Types
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <ProductTypeFiltersComponent
                                    available={availableProductTypes}
                                    selected={selectedProductTypes}
                                    onChange={onSelectedProductType}
                                />
                            </Paper>
                        </Box>
                        <Button style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 10,
                            right: 10,
                        }} variant={'contained'} sx={{my: 1}} onClick={() => apply()}>Apply</Button>
                    </>

                }
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Button ref={ref2} onClick={() => setOpen(!open)} style={{marginRight: "8px"}}>
                    Filter <FilterAltIcon/>
                </Button>
            </Tooltip>
        </CenterVertically>
    )
}