import React from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material";
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom';
import defaultTheme from "../../themes/theme";
import {Dashboard} from "../../pages/Dashboard";
import Partners from "../../pages/partners/Partners";
import ProductTypes from "../../pages/product-types/ProductTypes";
import Settings from "../../pages/settings/Settings";
import Partner from "../../pages/partner/Partner";
import {Main} from "../_reusable/Main/Main";
import {Login} from "../Login/Login";
import Countries from "../../pages/Countries";
import Country from "../../pages/Country/Country";

export function App() {
    const router = createBrowserRouter([
        {
            element: <Login/>,
            path: '/login',
        },
        {
            element: <Main/>,
            children: [
                {
                    path: '/',
                    loader: () => redirect('/dashboard'),
                },
                {
                    path: '/dashboard',
                    element: <Dashboard/>
                },
                {
                    path: '/partners',
                    element: <Partners/>
                },
                {
                    path: '/partners/:partnerId',
                    element: <Partner/>
                },
                {
                    path: '/countries',
                    element: <Countries/>
                },
                {
                    path: '/countries/:countryCode',
                    element: <Country/>
                },
                {
                    path: '/products',
                    element: <ProductTypes/>
                },
                {
                    path: '/settings',
                    element: <Settings/>
                },
                {
                    path: '/country/:countryId',
                    element: <Country/>,
                }
            ],
        }
    ]);

    return (
        <React.StrictMode>
            <ThemeProvider theme={defaultTheme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </React.StrictMode>
    );
}