import * as React from 'react';
import { styled } from '@mui/material/styles';
import {scaleLinear} from "d3-scale";

interface ProgressBarProps {
    value: number;
}

export const Root = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 26,
    borderRadius: 2,
}));

export const Value = styled('div')({
    position: 'absolute',
    lineHeight: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});

export const Bar = styled('div')({
    height: '100%',
    '&.low': {
        backgroundColor: '#f44336',
    },
    '&.medium': {
        backgroundColor: '#efbb5aa3',
    },
    '&.high': {
        backgroundColor: '#088208a3',
    },
});

export const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
    const { value } = props;
    const valueInPercent = value * 100;

    return (
        <Root>
            <Value>{`${valueInPercent.toFixed(2)} %`}</Value>
            <Bar
                // className={clsx({
                //     low: valueInPercent < 30,
                //     medium: valueInPercent >= 30 && valueInPercent <= 70,
                //     high: valueInPercent > 70,
                // })}
                style={{ maxWidth: `${valueInPercent.toFixed(2)}%`, height: "100%", backgroundColor: barColorScale(value).toString(), }}
            />
        </Root>
    );
});

const barColorScale = scaleLinear<string>().domain([0, 1]).range([ '#f44336', '#088208a3',])