import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";

const weightsData = [
    {
        category: "General Weights",
        weights: [
            { name: 'Products x value', description: 'Weighting factor for product-related risks' },
            { name: 'Clients x value', description: 'Weighting factor for client-related risks' },
            { name: 'Climate Weight', description: 'Measures impact of climate factors' },
            { name: 'Economic Complexity', description: 'Measures economic diversity and sophistication' },
        ]
    },
    {
        category: "Governance Weights",
        weights: [
            { name: 'Political Stability Weight', description: 'Assesses the stability of political systems' },
            { name: 'Rule of Law Weight', description: 'Evaluates the strength and impartiality of legal systems' },
            { name: 'Control of Corruption Weight', description: 'Measures the extent of corruption control' },
            { name: 'Regulatory Quality Weight', description: 'Assesses the quality of regulations and their implementation' },
            { name: 'Government Effectiveness Weight', description: 'Evaluates the quality of public services and policy implementation' },
            { name: 'Voice and Accountability Weight', description: 'Measures the degree of citizen participation in government' },
        ]
    },
    {
        category: "Social Weights",
        weights: [
            { name: 'Peace Weight', description: 'Assesses the level of peace and absence of violence' },
            { name: 'Lpop Weight', description: 'Related to population factors' },
            { name: 'Ldiscrimpop Weight', description: 'Measures discrimination within population segments' },
            { name: 'Discrimpop Weight', description: 'Assesses overall discrimination in the population' },
        ]
    },
    {
        category: "Historical Weights",
        weights: [
            { name: 'Onset Ko Weight', description: 'Related to the onset of specific events or conditions' },
            { name: 'Incidence Weight', description: 'Measures the frequency of certain events or conditions' },
            { name: 'Warhist Weight', description: 'Evaluates the historical context of conflicts' },
        ]
    },
];

const weightsSupplierData = [
    {
        category: "Company Profile",
        weights: [
            { name: 'Company Age', description: 'Weight based on how long the company has been operating' },
            { name: 'Number of Employees', description: 'Weight factor for company size based on employee count' },
            { name: 'Annual Revenue', description: 'Weight based on the company\'s annual revenue' },
            { name: 'Geographic Spread', description: 'Weight factor for the number of countries the supplier operates in' },
        ]
    },
    {
        category: "Industry Classification",
        weights: [
            { name: 'NACE Code', description: 'Weight based on the Statistical Classification of Economic Activities in the European Community' },
            { name: 'NOGA Code', description: 'Weight based on the Swiss General Classification of Economic Activities' },
            { name: 'SIC Code', description: 'Weight based on the Standard Industrial Classification' },
            { name: 'Industry Risk Level', description: 'Weight factor for the inherent risk level of the supplier\'s industry' },
        ]
    },
    {
        category: "Financial Health",
        weights: [
            { name: 'Credit Score', description: 'Weight based on the supplier\'s credit rating' },
            { name: 'Debt-to-Equity Ratio', description: 'Weight factor for the company\'s financial leverage' },
            { name: 'Liquidity Ratio', description: 'Weight based on the company\'s ability to meet short-term obligations' },
            { name: 'Profitability Margin', description: 'Weight factor for the company\'s profit margin' },
        ]
    },
    {
        category: "Compliance and Certifications",
        weights: [
            { name: 'Quality Certifications', description: 'Weight based on ISO 9001 and other quality management certifications' },
            { name: 'Environmental Certifications', description: 'Weight factor for ISO 14001 and other environmental management certifications' },
            { name: 'Industry-Specific Certifications', description: 'Weight for certifications relevant to the supplier\'s specific industry' },
            { name: 'Compliance History', description: 'Weight based on the supplier\'s history of regulatory compliance' },
        ]
    },
    {
        category: "Supply Chain Factors",
        weights: [
            { name: 'On-Time Delivery Rate', description: 'Weight based on the supplier\'s track record of meeting delivery deadlines' },
            { name: 'Defect Rate', description: 'Weight factor for the quality of supplied goods or services' },
            { name: 'Supply Chain Transparency', description: 'Weight based on the visibility into the supplier\'s own supply chain' },
            { name: 'Geopolitical Risk', description: 'Weight factor for political and economic risks in the supplier\'s operating regions' },
        ]
    },
];

export default function WeightsDetails() {
    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Country Risk
            </Typography>
            {weightsData.map((category, index) => (
                <Accordion
                    key={index}
                    elevation={0}
                    sx={{
                        '&:before': { display: 'none' }, // Removes the default border
                        borderBottom: '1px solid rgba(0, 0, 0, .125)' // Adds a light border between accordions
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <Typography>{category.category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List dense disablePadding>
                            {category.weights.map((weight) => (
                                <ListItem key={weight.name} disableGutters>
                                    <ListItemText
                                        primary={<Typography variant="body2">{weight.name}</Typography>}
                                        secondary={<Typography variant="caption">{weight.description}</Typography>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Typography variant="h5" gutterBottom sx={{mt: 2}}>
                Partner Risk
            </Typography>
            {weightsSupplierData.map((category, index) => (
                <Accordion
                    key={index}
                    elevation={0}
                    sx={{
                        '&:before': { display: 'none' }, // Removes the default border
                        borderBottom: '1px solid rgba(0, 0, 0, .125)' // Adds a light border between accordions
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <Typography>{category.category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List dense disablePadding>
                            {category.weights.map((weight) => (
                                <ListItem key={weight.name} disableGutters>
                                    <ListItemText
                                        primary={<Typography variant="body2">{weight.name}</Typography>}
                                        secondary={<Typography variant="caption">{weight.description}</Typography>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}