import Container from "@mui/material/Container";
import React, {useEffect} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent, TextField, Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {addProductType, useAddProductType} from "../../api/useProductTypes";
import {ProductTypeDto, ProductTypeInputDto} from "../../model/ProductTypeDto";
import {getNumber} from "../settings/Weights";
import {Loading} from "../../components/_reusable/loading/Loading";
import AddIcon from '@mui/icons-material/Add';


export default function AddProductType(
    props: {
        reload: () => void
    }
) {

    const [open, setOpen] = React.useState(false);
    const [dto, setDto] = React.useState<ProductTypeInputDto>({
        rasffRiskScore: 0,
        riskLevel: "",
        subScope: ""
    });
    const {loading, productTypes, error, executeAddProductType} = useAddProductType();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    function responseReceived() {
        console.log("response received")
        setOpen(false)
        props.reload()
    }

    function save() {
        if(dto) {
            executeAddProductType(dto)
                .then(res => {
                    responseReceived();
                })
                .catch(e => console.log(e))
        }
    }

    function setSubscope(value: string) {
        if(dto) {
            setDto({...dto, subScope: value})
        }
    }

    function setScore(value: string) {
        if(dto) {
            setDto({...dto, rasffRiskScore: getNumber(value)})
        }
    }

    function setRiskLevel(value: string) {
        if(dto) {
            setDto({...dto, riskLevel: value})
        }
    }

    // if(loading) return (<Loading/>)

    const riskLevels = ['Very Low', 'Low', 'Medium', 'High', 'Very High'];



    return(
        <Box>
            <Button color="primary" onClick={handleClickOpen} startIcon={<AddIcon />}>Add product type</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Add Product Type</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <TextField
                                id="subscope"
                                label="Sub-scope"
                                fullWidth
                                value={dto?.subScope}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSubscope(event.target.value);
                                }}
                                style={{marginTop: "10px"}}
                            />
                            <TextField
                                id="rasffRiskScore"
                                label="RASFF Risk Score"
                                fullWidth
                                type="number"
                                value={dto?.rasffRiskScore}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setScore(event.target.value);
                                }}
                                style={{marginTop: "10px"}}
                            />
                            <FormControl fullWidth style={{marginTop: "10px"}}>
                                <InputLabel id="risk-level-label">Risk level</InputLabel>
                                <Select
                                    labelId="risk-level-label"
                                    id="riskLevel"
                                    value={dto?.riskLevel || ''}
                                    label="Risk level"
                                    onChange={(event) => {
                                        setRiskLevel(event.target.value as string);
                                    }}
                                >
                                    {riskLevels.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={save}>Save</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );

}
