import React from "react";
import {CircularProgress} from "@mui/material";
import {CenterVerticallyAndHorizontally} from "../CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";


export function Loading() {
    return (
        <CenterVerticallyAndHorizontally>
            <CircularProgress />
        </CenterVerticallyAndHorizontally>
    );
}