import React from 'react';
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import layoutProps from "../props";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import LogoutIcon from '@mui/icons-material/Logout';
import {useLocation, useNavigate} from "react-router-dom";

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: layoutProps.drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface SideBar {
    drawerOpen: boolean;
    toggleDrawer: () => void;
}

export function SideBar(props: SideBar) {
    const navigate = useNavigate();
    const location = useLocation();

    function isActive(path: string): boolean {
        return location.pathname.startsWith(path);
    }

    const handleLogout = () => {
        // Implement logout logic here
        console.log("Logout clicked");
        // For example: clear local storage, redirect to login page, etc.
        // navigate('/login');
    };

    return (
        <StyledDrawer variant="permanent" open={props.drawerOpen}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={props.toggleDrawer}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <List component="nav">
                    <ListItemButton selected={isActive('/dashboard')} onClick={() => navigate('/dashboard')} >
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboard"/>
                    </ListItemButton>

                    <ListItemButton selected={isActive('/countries')} onClick={() => navigate('/countries')}>
                        <ListItemIcon>
                            <EmojiFlagsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Countries"/>
                    </ListItemButton>

                    <ListItemButton selected={isActive('/partners')} onClick={() => navigate('/partners')}>
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Partners"/>
                    </ListItemButton>

                    <ListItemButton selected={isActive('/products')} onClick={() => navigate('/products')}>
                        <ListItemIcon>
                            <CategoryIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Product types"/>
                    </ListItemButton>

                    <Divider sx={{my: 1}}/>
                    <ListItemButton selected={isActive('/settings')} onClick={() => navigate('/settings')}>
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Settings"/>
                    </ListItemButton>
                </List>

                <Box sx={{ flexGrow: 1 }} />

                <List>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
            </Box>
        </StyledDrawer>
    );
}