import {customAxios} from "./customAxios";
import {WeightsDto} from "../model/WeightsDto";
import {useState} from "react";

export async function getWeights(): Promise<WeightsDto> {
    const response = await customAxios.get<WeightsDto>('/v1/settings/weights', );
    return response.data;
}


export function useSaveWeights() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<WeightsDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: WeightsDto) {
        try {
            setLoading(true);
            const data = await saveWeights(dto);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

export async function saveWeights(weights: WeightsDto): Promise<WeightsDto> {
    const response = await customAxios.post<WeightsDto>('/v1/settings/weights', weights);
    return response.data;
}
