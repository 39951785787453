import {customAxios} from "./customAxios";
import {useState} from "react";


export function useRecalculate() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute() {
        try {
            setLoading(true);
            const res = await recalculateClientRisks();
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, error, execute
    };
}
export async function recalculateClientRisks(): Promise<void> {
    const response = await customAxios.put('/v1/client/recalculate', );
    return response.data;
}

