import React from 'react';
import {Button, FormControlLabel, Checkbox, TextField, CircularProgress, styled, Stack} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {usePartnersImport} from "../../api/partnersImport";
import {Loading} from "../../components/_reusable/loading/Loading";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const validationSchema = Yup.object({
    file: Yup.mixed().required("A file is required"),
    keepOnlyFood: Yup.bool(),
    deleteOld: Yup.bool()
});

interface ImprtProps {
    keepOnlyFood: boolean
    deleteOld: boolean
}

export default function PartnersImport()  {

    const {loading, error, execute} = usePartnersImport();

    const formik = useFormik({
        initialValues: {
            file: undefined,
            keepOnlyFood: true,
            deleteOld: true
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('file', values.file!!);
            formData.append('props', JSON.stringify({keepOnlyFood: values.keepOnlyFood, deleteOld: values.deleteOld} as ImprtProps));
            execute(formData);
        }
    });

    function getFileName(file: File | undefined): string {
        if (file) {
            return file.name
        }
        return ""
    }

    function getUploadText(file: File | undefined) {
        if (file) {
            return file.name
        }
        return "Select file"
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon/>}
                    fullWidth
                >
                    {getUploadText(formik.values.file)}
                    <VisuallyHiddenInput type="file" onChange={(event) => {
                        if (event?.currentTarget?.files) {
                            formik.setFieldValue('file', event.currentTarget.files[0]);
                        }
                    }}/>
                </Button>
                <FormControlLabel
                    control={<Checkbox checked={formik.values.keepOnlyFood} onChange={formik.handleChange}
                                       name="keepOnlyFood"/>}
                    label="Keep food only partners"
                />
                <FormControlLabel
                    control={<Checkbox checked={formik.values.deleteOld} onChange={formik.handleChange}
                                       name="deleteOld"/>}
                    label="Delete old partners"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    fullWidth
                >
                    {loading ? <Loading/> : "Upload"}
                </Button>
            </Stack>
        </form>
    );
};