import React, { useEffect, useState } from "react";
import { getWeights, useSaveWeights } from "../../api/getWeights";
import { Button, TextField, Grid, Box, Typography } from "@mui/material";
import { WeightsDto } from "../../model/WeightsDto";
import { useRecalculate } from "../../api/recalculate";
import { Loading } from "../../components/_reusable/loading/Loading";

export function getNumber(value: string): number {
    return Number.parseFloat(value);
}

export default function Weights() {
    const [weights, setWeights] = useState<WeightsDto>();
    const { loading: loadingSave, execute: executeSave } = useSaveWeights();
    const { loading: loadingRecalculate, execute: executeRecalculate } = useRecalculate();

    useEffect(() => {
        fetchWeights();
    }, []);

    function fetchWeights() {
        getWeights().then(res => setWeights(res)).catch(err => console.log(err))
    }

    function updateWeight(field: keyof WeightsDto, value: string) {
        if (weights) {
            setWeights({ ...weights, [field]: getNumber(value) })
        }
    }

    if (!weights) {
        return <Loading />;
    }

    function save() {
        if (weights) {
            executeSave(weights).then(r => setWeights(r));
        }
    }

    function recalculate() {
        executeRecalculate().then(() => fetchWeights())
    }

    const weightFields = [
        { id: "xForProducts", label: "Products x value" },
        { id: "xForClient", label: "Clients x value" },
        { id: "climateWeight", label: "Climate weight" },
        { id: "eciWeight", label: "Economic Complexity" },
        { id: "politicalStabilityWeight", label: "Political stability weight" },
        { id: "ruleOfLawWeight", label: "Rule of law weight" },
        { id: "corruptionWeight", label: "Control of corruption weight" },
        { id: "regulatoryWeight", label: "Regulatory quality weight" },
        { id: "governmentWeight", label: "Government effectiveness weight" },
        { id: "voiceWeight", label: "Voice and accountability weight" },
        { id: "peaceWeight", label: "Peace weight" },
        { id: "lpopWeight", label: "Lpop weight" },
        { id: "ldiscrimpopWeight", label: "l discrim pop weight" },
        { id: "discrimpopWeight", label: "Discrim pop weight" },
        { id: "onsetKoWeight", label: "Onset Ko weight" },
        { id: "incidenceWeight", label: "Incidence weight" },
        { id: "warhistWeight", label: "Warhist weight" },
    ];

    const weightPartnerFields = [
        // Company Profile
        { id: "companyAgeWeight", label: "Company Age" },
        { id: "employeeCountWeight", label: "Number of Employees" },
        { id: "annualRevenueWeight", label: "Annual Revenue" },
        { id: "geographicSpreadWeight", label: "Geographic Spread" },

        // Industry Classification
        { id: "naceCodeWeight", label: "NACE Code" },
        { id: "nogaCodeWeight", label: "NOGA Code" },
        { id: "sicCodeWeight", label: "SIC Code" },
        { id: "industryRiskLevelWeight", label: "Industry Risk Level" },

        // Financial Health
        { id: "creditScoreWeight", label: "Credit Score" },
        { id: "debtToEquityRatioWeight", label: "Debt-to-Equity Ratio" },
        { id: "liquidityRatioWeight", label: "Liquidity Ratio" },
        { id: "profitabilityMarginWeight", label: "Profitability Margin" },

        // Compliance and Certifications
        { id: "qualityCertificationsWeight", label: "Quality Certifications" },
        { id: "environmentalCertificationsWeight", label: "Environmental Certifications" },
        { id: "industrySpecificCertificationsWeight", label: "Industry-Specific Certifications" },
        { id: "complianceHistoryWeight", label: "Compliance History" },

        // Supply Chain Factors
        { id: "onTimeDeliveryRateWeight", label: "On-Time Delivery Rate" },
        { id: "defectRateWeight", label: "Defect Rate" },
        { id: "supplyChainTransparencyWeight", label: "Supply Chain Transparency" },
        { id: "geopoliticalRiskWeight", label: "Geopolitical Risk" },
    ];
    return (
        <Box>
            <Typography variant="h5" gutterBottom sx={{mb: 2}}>
                Country Risk
            </Typography>
            <Grid container spacing={2}>
                {weightFields.map((field) => (
                    <Grid item xs={12} sm={6} md={4} key={field.id}>
                        <TextField
                            id={field.id}
                            label={field.label}
                            fullWidth
                            type="number"
                            value={weights[field.id as keyof WeightsDto]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateWeight(field.id as keyof WeightsDto, event.target.value);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography variant="h5" gutterBottom sx={{mt: 2, mb: 2}}>
                Partner Risk
            </Typography>
            <Grid container spacing={2}>
                {weightPartnerFields.map((field) => (
                    <Grid item xs={12} sm={6} md={4} key={field.id}>
                        <TextField
                            id={field.id}
                            label={field.label}
                            fullWidth
                            type="number"
                            value={weights[field.id as keyof WeightsDto]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateWeight(field.id as keyof WeightsDto, event.target.value);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{
                mt: 3,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    disabled={loadingRecalculate || loadingSave}
                    fullWidth
                >
                    Save weights
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={recalculate}
                    disabled={loadingRecalculate || loadingSave}
                    fullWidth
                >
                    Recalculate client risks
                </Button>
            </Box>
            {(loadingRecalculate || loadingSave) && <Loading />}
        </Box>
    )
}