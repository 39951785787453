import React, {useState} from "react";
import {Box, CssBaseline, Toolbar} from "@mui/material";
import {Outlet} from "react-router-dom";
import {AppBar} from "./components/AppBar/AppBar";
import {SideBar} from "./components/SideBar/SideBar";
import {Authenticated} from "../Authenticated/Authenticated";

export function Main() {
    const [drawerOpen, setDrawerOpen] = useState(true);

    return (
        // @ts-ignore
        <Authenticated>
            <Box sx={{display: 'flex', overflow: 'hidden'}} id={'vrsni box'}>
                <CssBaseline/>
                <AppBar drawerOpen={drawerOpen} toggleDrawer={() => setDrawerOpen(prevState => !prevState)}/>
                <SideBar drawerOpen={drawerOpen} toggleDrawer={() => setDrawerOpen(prevState => !prevState)}/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',

                        display: 'flex',
                        flexDirection: 'column',

                        overflow: 'hidden',
                    }}
                >
                    <Toolbar id={'prazni toolbar'}/>
                    <Box
                        id={'outlet box'}
                        sx={{
                            flexGrow: 1,
                            overflow: 'auto'
                        }}
                    >
                        <Outlet/>
                    </Box>
                </Box>
            </Box>
        </Authenticated>
    );
}