import {CountryDto} from "../model/CountryDto";
import {customAxios} from "./customAxios";
import {useState} from "react";


export function useCountries() {
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<CountryDto[]>([]);
    const [error, setError] = useState<Error>();

    async function executeGetCountries() {
        try {
            setLoading(true);
            const data = await getCountries();
            setCountries(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, countries, error, executeGetCountries
    };
}


export async function getCountries(): Promise<CountryDto[]> {
    const response = await customAxios.get<CountryDto[]>('/v1/country', );
    return response.data;
}


export function useCountry() {
    const [loading, setLoading] = useState<boolean>(false);
    const [country, setCountry] = useState<CountryDto>();
    const [error, setError] = useState<Error>();

    async function executeGetCounty(iso3: string) {
        try {
            setLoading(true);
            const data = await getCounty(iso3);
            setCountry(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, country, error, executeGetCounty
    };
}

export async function getCounty(iso3: string): Promise<CountryDto> {
    const response = await customAxios.get<CountryDto>('/v1/country/' + iso3, );
    return response.data;
}
